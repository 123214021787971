import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import reducer from '../reducers/filter';

const FilterContext = createContext();
export default FilterContext;

export function FilterProvider({ children }) {
	const [data, dispatch] = useReducer(reducer, {
		submit: false,
		open: false,
		lastFilter: {},
		filter: {}, // active filter
		cnt: 0,

		// Fields related settings
		fieldsProps: {
			price: { resetCnt: 0 } // on every remove inc this int for one, then componetn input-range-slider.js will be reseted/re-rednered to default falue
		}
	});

	return (<FilterContext.Provider value={{ data, dispatch }}>{children}</FilterContext.Provider>);
}

FilterProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export const useStore = () => useContext(FilterContext);
