import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = React.memo(({ children, variation, name, value, onChange, checked, uniqueid, classes, disabled }) => {
	const c = ['checkbox', variation];
	if (classes) c.push(classes);
	const uid = uniqueid || value;

	return (
		<label className={c.join(' ')} htmlFor={`${name}${uid}`}>
			<div className={`input ${checked ? 'active' : ''} ${disabled ? 'disabled' : ''}`}>
				<input type="checkbox" name={name} value={value} id={`${name}${uid}`} onChange={onChange} checked={checked} disabled={disabled} />
				<span className="checkmark" />
			</div>
			{children}
		</label>
	);
});

Checkbox.defaultProps = {
	value: 1,
	checked: false,
	variation: 'default',
	uniqueid: null,
	classes: null,
	disabled: false
};

Checkbox.propTypes = {
	children: PropTypes.node.isRequired,
	name: PropTypes.string.isRequired,
	variation: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	onChange: PropTypes.func.isRequired,
	checked: PropTypes.bool,
	uniqueid: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	classes: PropTypes.string,
	disabled: PropTypes.bool
};

export default Checkbox;
