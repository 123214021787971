import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import useFilter from '../../_hooks/useFilter';
import useCoderegister from '../../_hooks/useCoderegister';

const ReactSelect = React.memo(({ name, list, state, selected, placeholder, onChange, propsByType, crToOptions, classes, disabled }) => {
	const { getFilterNodeText } = useFilter();
	const { getCrText, clientCrFields } = useCoderegister();

	const [changed, setChanged] = useState(false);
	const [options, setOptions] = useState(crToOptions(list)); // redesign object in array [list.map(({ text, id }) => { return { label: text, value: id }; })]
	const [sel, setSel] = useState((state && state[name])
		? { label: getCrText(state[name].value, clientCrFields[name]), value: state[name].value }
		: selected);

	const c = ['select'];
	if (sel) c.push('active');
	if (classes) c.push(classes);

	// On local state change, update outside/parent component
	useEffect(() => {
		if (changed && sel && typeof sel === 'object') {
			onChange({ target: { name, value: sel.value } });
		}
	}, [sel]);

	// On selected (from outside/parent) change (when prop selected is changed, not when local state is changed)
	useEffect(() => {
		if (!state) {
			if ((selected && selected.length > 0) || typeof selected === 'object') {
				setSel(typeof selected === 'object' ? selected : { label: getFilterNodeText(name, selected), value: selected }); // prefill from outsite, via "selected" prop (generate object) or just append object (onChange evnet in <Select /> - user change)
			} else if (sel) {
				setSel(null);
			}
		}
	}, [selected]);

	// If list is changed, options should be rerendered again
	useEffect(() => setOptions(crToOptions(list)), [list]);

	return (
		<Select
			placeholder={placeholder}
			value={sel}
			className={`${c.join(' ')} ${disabled ? 'disabled' : ''}`}
			onChange={(v) => { setSel(v); if (changed === false) setChanged(true); }}
			options={options}
			theme={theme => ({
				...theme,
				colors: {
					...theme.colors,
					primary: '#6872E3' // $primary-color in css
				}
			})}
		/>
	);
});

ReactSelect.defaultProps = {
	state: null,
	selected: null,
	placeholder: null,
	propsByType: {},
	classes: null,
	disabled: false
};

ReactSelect.propTypes = {
	name: PropTypes.string.isRequired,
	state: PropTypes.shape({}),
	list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	selected: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.string)
	]),
	propsByType: PropTypes.shape({}),
	crToOptions: PropTypes.func.isRequired,
	classes: PropTypes.string,
	disabled: PropTypes.bool
};

export default ReactSelect;
