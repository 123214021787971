import { useState, useEffect } from 'react';
// import createApolloClient from '../../apollo/client';

import { useStore as loaderStore } from '../../contexts/loader';
import useGQL from './useGQL';
// import { setContext } from '../../helpers/gql';

const useCoderegister = () => {
	const { data } = loaderStore();
	const { isCoderegisterLoaded } = data;

	// const client = createApolloClient();

	const getCrText = (id, name) => {
		if (data.coderegister && data.coderegister[name]) {
			const search = data.coderegister[name].filter(row => row.id === id);
			if (search[0]) {
				return search[0].text;
			}
		}

		return null;
	};

	// == Get already loader, from localstore

	const getLocalData = () => {
		const storage = process.browser ? window.localStorage.getItem('loader') : {};
		const localData = JSON.parse(storage) || {};
		return localData.coderegister || {};
	};

	const [linkedCoderegisters] = useState({
		// Affacted field (must be filtered): parent, by which affected field is filterd
		aa_real_estate_subtype: 'aa_real_estate_type',
		aa_geo_location_level2: 'aa_geo_location_level1', // administrtive unit by region
		// aa_geo_location_level3: 'aa_geo_location_level2', // municipality by municipality
		aa_geo_location_level3: 'aa_geo_location_level1', // municipality by region [EDIT IN TABEL CATALOG?]
		aa_geo_location_level4: 'aa_geo_location_level3' // district by municipality
	});

	const [clientCrFields] = useState({
		transaction_id: 'aa_real_estate_transaction',
		floor_id: 'aa_floor', // virtual cr (by project)
		floar_cnt_id: 'aa_floor_cnt', // virtual cr (by project)
		nearby_type_id: 'aa_nearby_type', // virtual cr (by project)
		energy_certificate_id: 'aa_energy_certificate', // virtual cr (by project)
		type_id: 'aa_real_estate_type',
		subtype_id: 'aa_real_estate_subtype',
		geo_location_level1_id: 'aa_geo_location_level1', // virtual cr (global)
		geo_location_level2_id: 'aa_geo_location_level2', // virtual cr (global)
		geo_location_level3_id: 'aa_geo_location_level3', // virtual cr (global)
		geo_location_level4_id: 'aa_geo_location_level4', // virtual cr (global)
		postal_code_id: 'aa_postal_code',
		postal_postal_code: 'aa_postal_code', // Profile, contact
		postal_organisation_postal_code: 'aa_postal_code', // Profile, contact
		auction_sequence_id: 'aa_auction_sequence', // virtual cr (by project)
		auction_status_id: 'aa_auction_status',
		auction_offer_type_id: 'aa_auction_offer_type', // virtual cr (by project)
		real_estate_additional: 'aa_real_estate_additional', // virtual cr (by project)
		real_estate_luxury: 'aa_real_estate_luxury', // virtual cr (by project)
		real_estate_room: 'aa_real_estate_room', // virtual cr (by project)
		real_estate_connector: 'aa_real_estate_connector', // virtual cr (by project)
		real_estate_heating: 'aa_real_estate_heating', // virtual cr (by project)
		auction_additional: 'aa_auction_additional' // virtual cr (by project)
	});

	const getCoderegister = (name) => {
		if (Object.values(data.coderegister).length > 0) {
			const localData = data.coderegister;

			// Return from local storage, if exists
			if (localData[name]) {
				return localData[name];
			}

			// Try to load from server, if not exist in local storage (and store in local storage)
			const serverData = loadCoderegister(name);
			if (serverData) {
				return serverData;
			}
		}

		return [];
	};

	const getCoderegisters = (crList) => {
		const collector = {};

		Object.keys(crList).map((name) => {
			if (linkedCoderegisters[name]) {
				collector[name] = crList[linkedCoderegisters[name]] ? getCoderegister(name).filter(row => row.parent_id === crList[linkedCoderegisters[name]]) : [];
				return true;
			}

			collector[name] = getCoderegister(name);
			return true;
		});

		return collector;
	};

	// == Load from server & update in local store

	const [variables, setVariables] = useState({});
	const { query } = useGQL('coderegister>loader');
	const { isFetching, isSuccess, data: serverCrData, refetch } = query(variables, { enabled: false });

	const loadCoderegister = n => setVariables({ name: n });

	useEffect(() => {
		if (isFetching === false && variables.name) refetch();
	}, [variables])

	useEffect(() => {
		if (isFetching === false && isSuccess === true) {
			if (serverCrData.request) {
				updateLocalCoderegisters(JSON.parse(serverCrData.request.loader), true);
			}
		}
	}, [isFetching]);

	const updateLocalCoderegisters = (serverData, updateLocalStore) => {
		if (process.browser) {
			// Get last saved local data
			const localData = getLocalData();
			const localChecksums = localData.checksums || {};

			const d = {
				name: 'coderegister',
				checksums: { ...localChecksums, ...serverData.checksums },
				data: { ...localData.data, ...serverData.data }
			};

			if (updateLocalStore === true) {
				// Load complete local store loader and override just coderegister section
				const storage = JSON.parse(window.localStorage.getItem('loader')) || {};
				window.localStorage.setItem('loader', JSON.stringify({ ...storage, coderegister: d }));
				return true;
			}

			return d;
		}

		return serverData;
	};

	return { isCoderegisterLoaded, getCoderegister, getCoderegisters, updateLocalCoderegisters, loadCoderegister, clientCrFields, getCrText };
};

export default useCoderegister;
