import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SelectMultiple from './select/multiple';
import SelectReact from './select/react-select';
import SelectDefault from './select/default';
import useMobile from '../_hooks/useMobile';

// Helpers

const crToOptions = (l) => {
	return l.map(({ text, id }) => { return { label: text, value: id }; });
};

/*
const optionsToCr = (l) => {
	return l.map(({ label, value }) => { return { text: label, id: value }; });
};
*/

const ConditionalWrapper = ({ condition, wrapper, children }) => {
	return condition ? wrapper(children) : children;
};

const SelectWrapper = React.forwardRef((props, ref) => {
	const { isPhone } = useMobile();
	const { name, state, children, variation, list, reset } = props;
	const input = (state && state[name]) ? state[name] : {};

	const [options, setOptions] = useState(list);

	useEffect(() => {
		setOptions(reset ? [reset, ...list] : list);
	}, [list]);

	return (
		<ConditionalWrapper
			condition={ref}
			wrapper={chlds => <span ref={ref}>{chlds}</span>}
		>
			{children}
			{variation === 'multiple' && <SelectMultiple {...props} list={options} crToOptions={crToOptions} />}
			{variation === 'reactSelect' && isPhone === false && <SelectReact {...props} list={options} crToOptions={crToOptions} />}
			{(variation === 'default' || (variation === 'reactSelect' && isPhone === true)) && <SelectDefault {...props} list={options} />}
			{input.error && <p className="error">{input.error}</p>}
		</ConditionalWrapper>
	);
});

SelectWrapper.defaultProps = {
	state: null,
	children: null,
	selected: null,
	placeholder: null,
	variation: 'default',
	propsByType: {},
	classes: null,
	reset: null
};

SelectWrapper.propTypes = {
	name: PropTypes.string.isRequired,
	state: PropTypes.shape({}),
	list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	selected: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.string)
	]),
	children: PropTypes.node,
	variation: PropTypes.string,
	propsByType: PropTypes.shape({}),
	classes: PropTypes.string,
	reset: PropTypes.shape()
};

export default SelectWrapper;
