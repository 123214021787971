import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Default = ({ name, list, state, selected, placeholder, onChange, classes, disabled }) => {
	const input = (state && state[name]) ? state[name] : {};
	const s = input.value || selected;

	useEffect(() => {
		setActive(s && s.length > 0);
	}, [selected]);

	const [active, setActive] = useState(false);

	const c = [];
	if (active) c.push('active');
	if (classes) c.push(classes);

	return (
		<select name={name} value={s || ''} onChange={(e) => { onChange(e); }} className={c.join(' ')} disabled={disabled}>
			{placeholder && <option value="">{placeholder}</option>}
			{list.length > 0 && list.map(row => <option key={row.id} value={row.id}>{row.text}</option>)}
		</select>
	);
};

Default.defaultProps = {
	state: {},
	selected: '',
	placeholder: null,
	classes: null,
	disabled: false
};

Default.propTypes = {
	name: PropTypes.string.isRequired,
	state: PropTypes.shape({}),
	list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	selected: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.string)
	]),
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	classes: PropTypes.string,
	disabled: PropTypes.bool
};

export default Default;
