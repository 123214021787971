import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Radiobutton = React.memo(({ name, list, defaultValue, state, variation, onChange, classes }) => {
	const input = (state && state[name]) ? state[name] : {};
	const [lastValue, setLastValue] = useState(input.value || defaultValue);

	const onLocalChange = (e) => {
		const { target } = e;
		const { value: v } = target;
		setLastValue(v);
		onChange(e);
	};

	useEffect(() => {
		if (defaultValue !== -1) {
			setLastValue(defaultValue);
		}
	}, [defaultValue]);

	return (
		<>
			{list.map((row, index) => {
				const { id, text, component, disabled } = row;
				const checked = id.toString() === lastValue;
				const idx = `${Math.random()}_${index}`;

				const c = ['radiobutton', variation];
				if (classes) c.push(classes);
				if (checked) c.push('active');
				if (disabled) c.push('disabled');

				return (
					<label key={idx} className={c.join(' ')} htmlFor={idx}>
						<div className="input">
							<input type="radio" name={name} value={id} id={idx} onChange={onLocalChange} checked={checked} disabled={disabled} />
							<span className="checkmark" />
						</div>
						{typeof text === 'string' && <span>{text}</span>}
						{typeof text === 'function' && text()}
						{component && component()}
					</label>
				);
			})}
			{input.error && <p className="error">{input.error}</p>}
		</>
	);
});

Radiobutton.defaultProps = {
	variation: 'default',
	defaultValue: -1,
	state: null,
	classes: null
};

Radiobutton.propTypes = {
	name: PropTypes.string.isRequired,
	list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	defaultValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	state: PropTypes.shape({}),
	variation: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	classes: PropTypes.string
};

export default Radiobutton;
