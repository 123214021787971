const reducer = (state, action) => {
	switch (action.type) {
	case 'TOGGLE': {
		return { ...state, open: !state.open };
	}

	case 'CLOSE': {
		if (state.open) {
			return { ...state, open: false };
		}

		return state;
	}

	case 'UPDATE_CNT': {
		const { payload } = action;
		return { ...state, cnt: payload.cnt };
	}

	case 'SUBMIT_FILTER_SAVE': {
		const { payload } = action;
		return { ...state, submit: payload.submit };
	}

	case 'RESET_SUBMIT': {
		return { ...state, submit: false };
	}

	case 'SET_FILTER': {
		const { payload } = action;
		const { filter } = payload;
		return { ...state, filter };
	}

	case 'RESET_FILTER': {
		return { ...state, filter: {}, cnt: 0 };
	}

	case 'SET_LAST_FILTER': {
		const { payload } = action;
		return { ...state, lastFilter: payload };
	}

	case 'SET_FILTER_FIELDS_PROPS': {
		const { payload } = action;
		const { name, value } = payload;
		return { ...state, fieldsProps: { ...state.fieldsProps, [name]: value } };
	}

	default:
		return state;
	}
};

export default reducer;
